import * as Types from '../../__generated_types__/types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserQuestionnairesQueryVariables = Types.Exact<{
  xTenantId: Types.Scalars['ID']['input'];
  userProfileId: Types.Scalars['ID']['input'];
  filter?: Types.InputMaybe<Array<Types.InputMaybe<Types.AggregateFilters>> | Types.InputMaybe<Types.AggregateFilters>>;
}>;


export type UserQuestionnairesQuery = { __typename?: 'Query', userQuestionnaires: { __typename?: 'PagedUserProfileQuestionnaires', data?: Array<{ __typename?: 'UserProfileQuestionnaire', questionnaireTemplateId?: string | null, status?: Types.UserProfileQuestionnaireStatus | null } | null> | null } };


export const UserQuestionnairesDocument = gql`
    query UserQuestionnaires($xTenantId: ID!, $userProfileId: ID!, $filter: [AggregateFilters]) {
  userQuestionnaires(
    xTenantID: $xTenantId
    userProfileId: $userProfileId
    filter: $filter
  ) {
    data {
      questionnaireTemplateId
      status
    }
  }
}
    `;

/**
 * __useUserQuestionnairesQuery__
 *
 * To run a query within a React component, call `useUserQuestionnairesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuestionnairesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuestionnairesQuery({
 *   variables: {
 *      xTenantId: // value for 'xTenantId'
 *      userProfileId: // value for 'userProfileId'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserQuestionnairesQuery(baseOptions: Apollo.QueryHookOptions<UserQuestionnairesQuery, UserQuestionnairesQueryVariables> & ({ variables: UserQuestionnairesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>(UserQuestionnairesDocument, options);
      }
export function useUserQuestionnairesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>(UserQuestionnairesDocument, options);
        }
export function useUserQuestionnairesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>(UserQuestionnairesDocument, options);
        }
export type UserQuestionnairesQueryHookResult = ReturnType<typeof useUserQuestionnairesQuery>;
export type UserQuestionnairesLazyQueryHookResult = ReturnType<typeof useUserQuestionnairesLazyQuery>;
export type UserQuestionnairesSuspenseQueryHookResult = ReturnType<typeof useUserQuestionnairesSuspenseQuery>;
export type UserQuestionnairesQueryResult = Apollo.QueryResult<UserQuestionnairesQuery, UserQuestionnairesQueryVariables>;